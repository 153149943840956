const burger = document?.querySelector('.burger');
const header = document?.querySelector('.header') as HTMLElement;
const nav = document?.querySelector('.header__nav');
const content = document?.querySelector('.content-show');
const navItem = nav?.querySelectorAll('a');
const headerIcons = header?.querySelectorAll('.burger-item');

burger?.addEventListener('click', () => {
  content?.classList.toggle('content-show--active');
  document.body.classList.toggle('stop-scrol');
  burger?.classList.toggle('burger--active');
  nav?.classList.toggle('nav-visible');
});

content?.addEventListener('click', () => {
  content?.classList.remove('content-show--active');
  document.body.classList.remove('stop-scrol');
  burger?.classList.remove('burger--active');
  nav?.classList.remove('nav-visible');
});

navItem?.forEach((item) => {
  item.addEventListener('click', (e) => {
    const target = item.getAttribute('href');
    if (target?.charAt(0) === '#') {
      e.preventDefault();
      const elem = document.querySelector(target) as HTMLElement;

      const scrollSize = elem.offsetTop - header.clientHeight;
      window.scrollTo({
        top: scrollSize,
        left: 0,
        behavior: "smooth",
      });
    }
    navItem?.forEach((item) => {
      item.classList.remove('header__link--active');
    })

    item.classList.add('header__link--active');
    content?.classList.remove('content-show--active');
    document.body.classList.remove('stop-scrol');
    burger?.classList.remove('burger--active');
    nav?.classList.remove('nav-visible');
  });
});

headerIcons?.forEach((item) => {
  item.addEventListener('click', (e) => {
    const target = item.getAttribute('href');
    if (target?.charAt(0) === '#') {
      e.preventDefault();
      const elem = document.querySelector(target) as HTMLElement;

      const scrollSize = elem.offsetTop - header.clientHeight;
      window.scrollTo({
        top: scrollSize,
        left: 0,
        behavior: "smooth",
      });
    }
  });
});
