// @ts-ignore
import $ from "jquery";

declare global {
  interface Window {
    grecaptcha: any
  }
}

const contactForm = document.querySelector('.form-contact') as HTMLElement;
const collaborationForm = document.querySelector('.form') as HTMLElement;

const contactCaptcha = document.querySelector('.form-contact__captcha') as HTMLElement;

const body = document.body;
const form = document.querySelector('.form') as HTMLElement;

const collaborationFormBtn = document.querySelector('.form__button') as HTMLButtonElement;
const contactFormBtn = document.querySelector('.form-contact__button') as HTMLButtonElement;

const modalSuccess = document.querySelector('.modal-success') as HTMLElement;
const modalError = document.querySelector('.modal-error') as HTMLElement;

const inputName = document.querySelector('.input-name') as HTMLInputElement;
const inputEmail = document.querySelector('.input-email') as HTMLInputElement;
const textareaMessage = document.querySelector('.textarea-message') as HTMLTextAreaElement;
const inputCheckbox = document.querySelector('.input-checkbox') as HTMLInputElement;
const inputPhone = document.querySelector('.input-phone') as HTMLInputElement;

const labelMessage = document.querySelector('.label-message') as HTMLElement;
const labelName = document.querySelector('.label-name') as HTMLElement;
const labelEmail = document.querySelector('.label-email') as HTMLElement;
const labelCheckbox = document.querySelector('.form__label-checkbox') as HTMLInputElement;

const inputNameContact = document.querySelector('.input-name-contact') as HTMLInputElement;
const inputEmailContact = document.querySelector('.input-email-contact') as HTMLInputElement;
const inputCheckboxContact = document.querySelector('.input-checkbox-contact') as HTMLInputElement;
const textareaMessageContact = document.querySelector(
    '.textarea-message-contact'
) as HTMLTextAreaElement;


const errorDescription = document.querySelector(".modal-error__description") as HTMLElement

const labelNameContact = document.querySelector('.label-name-contact') as HTMLElement;
const labelEmailContact = document.querySelector('.label-email-contact') as HTMLElement;
const labelMessageContact = document.querySelector('.label-message-contact') as HTMLElement;
const labelCheckboxContact = document.querySelector(
    '.form-contact__label-checkbox-contact'
) as HTMLInputElement;

const validEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

const formTextareaValidate = document.querySelector('.form__textarea--error') as HTMLElement;
const formNameValidate = document.querySelector('.form__name--error') as HTMLElement;
const formEmailValidate = document.querySelector('.form__email--error') as HTMLElement;
const formConfirmValidate = document.querySelector('.form__confirm--error') as HTMLElement;
const formCaptchaValidate = document.querySelector('.form__captcha--error') as HTMLElement;


const formContactTextareaValidate = document.querySelector('.form-contact__textarea--error') as HTMLElement;
const formContactNameValidate = document.querySelector('.form-contact__name--error') as HTMLElement;
const formContactEmailValidate = document.querySelector('.form-contact__email--error') as HTMLElement;
const formContactConfirmValidate = document.querySelector('.form-contact__confirm--error') as HTMLElement;
const formContactCaptchaValidate = document.querySelector('.form-contact__captcha--error') as HTMLElement;

const formRecaptcha = document.querySelector('.form__captcha') as HTMLElement;
const contactRecaptcha = document.querySelector('.form-contact__captcha') as HTMLElement;


let captchaFormIsCheck = false;
let captchaFormContactIsCheck = false;

document.body.addEventListener('click', (e) => {
  const el = e.target as HTMLElement;


  if (el.classList.contains('form__button')) {
    e.preventDefault();

    if (inputName.value === '') {
      labelName.classList.add('form__label--error');
      formNameValidate.innerHTML = "Поле не должно быть пустым"
      formNameValidate.style.opacity = "1"
    } else {
      labelName.classList.remove('form__label--error');
      formNameValidate.style.opacity = "0"
    }



    if (!validEmail.test(inputEmail.value)) {
      labelEmail.classList.add('form__label--error');
      formEmailValidate.innerHTML = "Поле не должно быть пустым или почта не валидна"
      formEmailValidate.style.opacity = "1"
    } else {
      labelEmail.classList.remove('form__label--error');
      formEmailValidate.style.opacity = "0"
    }

    if (textareaMessage.value === '') {
      labelMessage.classList.add('form__label--error');
      formTextareaValidate.innerHTML = "Поле не должно быть пустым"
      formTextareaValidate.style.opacity = "1"
    } else {
      labelMessage.classList.remove('form__label--error');
      formTextareaValidate.style.opacity = "0"
    }

    if (!inputCheckbox.checked) {
      labelCheckbox.classList.add('form__label-checkbox--error');
      formConfirmValidate.innerHTML = "Поле не должно быть пустым"
      formConfirmValidate.style.opacity = "1"
    } else {
      labelCheckbox.classList.remove('form__label-checkbox--error');
      formConfirmValidate.style.opacity = "0"
    }

    if (
        inputName.value !== '' &&
        validEmail.test(inputEmail.value) &&
        textareaMessage.value !== '' &&
        inputCheckbox.checked &&
        captchaFormIsCheck
    ) {
      // body.classList.add('stop-scroll');
      // modal.classList.add('modal--show');

      inputPhone.value = '';
      inputName.value = '';
      inputEmail.value = '';
      textareaMessage.value = '';
      inputCheckbox.checked = false;
    }
  } else if (el.classList.contains('form-contact__button')) {
    e.preventDefault();

    if (inputNameContact.value === '') {
      labelNameContact.classList.add('form-contact__label--error');
      formContactNameValidate.innerHTML = "Поле не должно быть пустым"
      formContactNameValidate.style.opacity = "1"
    } else {
      labelNameContact.classList.remove('form-contact__label--error');
      formContactNameValidate.style.opacity = "0"
    }

    if (!validEmail.test(inputEmailContact.value)) {
      labelEmailContact.classList.add('form-contact__label--error');
      formContactEmailValidate.innerHTML = "Поле не должно быть пустым или почта не валидна"
      formContactEmailValidate.style.opacity =  "1"
    } else {
      labelEmailContact.classList.remove('form-contact__label--error');
      formContactEmailValidate.style.opacity = "0"
    }

    if (textareaMessageContact.value === '') {
      labelMessageContact.classList.add('form-contact__label--error');
      formContactTextareaValidate.innerHTML = "Поле не должно быть пустым"
      formContactTextareaValidate.style.opacity =  "1"
    } else {
      labelMessageContact.classList.remove('form-contact__label--error');
      formContactTextareaValidate.style.opacity = "0"
    }

    if (!inputCheckboxContact.checked) {
      labelCheckboxContact.classList.add('form-contact__label-checkbox-contact--error');
      formContactConfirmValidate.innerHTML = "Подтвердите согласие на обработку данных"
      formContactConfirmValidate.style.opacity =  "1"
    } else {
      labelCheckboxContact.classList.remove('form-contact__label-checkbox-contact--error');
      formContactConfirmValidate.style.opacity = "0";
    }

    if (
        inputNameContact.value !== '' &&
        validEmail.test(inputEmailContact.value) &&
        textareaMessageContact.value !== '' &&
        inputCheckboxContact.checked &&
        captchaFormContactIsCheck
    ) {
      inputNameContact.value = '';
      inputEmailContact.value = '';
      textareaMessageContact.value = '';
      inputCheckboxContact.checked = false;
    }
  }

  if (el.classList.contains('modal-error__button')) {
    body.classList.remove('stop-scroll');
    modalError.classList.remove('modal-error--show');
  }

  if (el.classList.contains('modal-success__button')) {
    body.classList.remove('stop-scroll');
    modalSuccess.classList.remove('modal-success--show');
  }
});

function check_field(field: string) {
  if (field === '') {
    return 0;
  }
  return 1;
}

function errorResponseDescription($message : string) {

  errorDescription.innerHTML = $message;

}

collaborationFormBtn?.addEventListener('click', (e) => {

  e.preventDefault();

  let token = window.grecaptcha.getResponse();

  if (token.length == 0) {
    //formRecaptcha.classList.add('form__captcha--error');
    formCaptchaValidate.innerHTML = "Капча невалидна или не введена"
    formCaptchaValidate.style.opacity = "1";
    window.grecaptcha.reset();
    captchaFormIsCheck = false;
    return;
  } else {
    //formRecaptcha.classList.remove('form__captcha--error');
    formCaptchaValidate.style.opacity = "0";
    captchaFormIsCheck = true;
  }


  let check =
      check_field(inputName.value) +
      check_field(inputEmail.value) +
      check_field(textareaMessage.value) +
      check_field(inputCheckbox.checked ? "checked" : '') +
      check_field(token)
  ;

  if (check === 5) {

    collaborationFormBtn.disabled = true;


    $.ajax({
      type: "POST",
      url: "/collaboration_form.php",
      data: {
        name: inputName.value,
        email: inputEmail.value,
        message: textareaMessage.value ,
        phone: inputPhone.value,
        gRecaptcha: token
      },
      success: function (response: any) {

        let responseParse = response.substring(response.indexOf("{"), response.indexOf("}") + 1);

        let data = JSON.parse(responseParse);

        if (data.success) {
          body.classList.add('stop-scroll');
          modalSuccess.classList.add('modal-success--show');
        } else if (data.error) {
          errorResponseDescription(data.error);
          body.classList.add('stop-scroll');
          modalError.classList.add('modal-error--show');
        }

        collaborationFormBtn.disabled = false;
        window.grecaptcha.reset();
      },
      error: (error: any, textStatus: string) => {
        errorResponseDescription(textStatus);
        body.classList.add('stop-scroll');
        modalError.classList.add('modal-error--show');

        collaborationFormBtn.disabled = false;
        window.grecaptcha.reset();
      },
    });
  }
});

contactFormBtn?.addEventListener('click', (e) => {

  e.preventDefault();

  let token = window.grecaptcha.getResponse(1);

  if (token.length == 0) {
    //formRecaptcha.classList.add('form__captcha--error');
    formContactCaptchaValidate.innerHTML = "Капча невалидна или не введена"
    formContactCaptchaValidate.style.opacity = "1";
    window.grecaptcha.reset(1);
    captchaFormContactIsCheck = false;
    return;
  } else {
    //formRecaptcha.classList.remove('form__captcha--error');
    formContactCaptchaValidate.style.opacity = "0";
    captchaFormContactIsCheck = true;
  }


  let check =
      check_field(inputNameContact.value) +
      check_field(inputEmailContact.value) +
      check_field(textareaMessageContact.value) +
      check_field(inputCheckboxContact.checked ? "checked" : '')  +
      check_field(token)

  if (check === 5) {

    contactFormBtn.disabled = true;

    $.ajax({
      type: "POST",
      url: "/contact_form.php",
      data: {
        name: inputNameContact.value,
        email: inputEmailContact.value,
        message: textareaMessageContact.value ,
        gRecaptcha: token
      },
      success: function (response: any) {

        let responseParse = response.substring(response.indexOf("{"), response.indexOf("}") + 1);

        let data = JSON.parse(responseParse);

        if (data.success) {
          body.classList.add('stop-scroll');
          modalSuccess.classList.add('modal-success--show');
        } else if (data.error) {
          errorResponseDescription(data.error);
          body.classList.add('stop-scroll');
          modalError.classList.add('modal-error--show');
        }

        contactFormBtn.disabled = false;
        window.grecaptcha.reset(1);

      },
      error: (error: any, textStatus: string) => {
        errorResponseDescription(textStatus);

        body.classList.add('stop-scroll');
        modalError.classList.add('modal-error--show');

        contactFormBtn.disabled = false;
        window.grecaptcha.reset(1);
      },
    });

  }
});

